.form-group {
  @apply mb-6 relative;
}

.form-input {
  &[disabled] {
    @apply bg-gray-200;
  }
}

/* non-input elements (like the Stripe card form) can be styled to look like an input */
div.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-width: 1px;
  padding-top: .5rem;
  padding-right: .75rem;
  padding-bottom: .5rem;
  padding-left: .75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

@layer components {
  .form-control {
    @apply form-input block w-full rounded-md border-gray-300 shadow-sm sm:text-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

    &[disabled], &[readonly] {
      @apply bg-gray-200;
    }

    &.error {
      @apply border-red-300;
    }
  }
}

select {
  @apply sm:text-sm;
}

select:not([multiple]) {
  @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

  &[disabled] {
    @apply bg-gray-200;
  }
}

select[multiple] {
  @apply block w-full mt-1 rounded-md border-gray-300 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

  &[disabled] {
    @apply bg-gray-200;
  }
}

label,
.label {
  @apply block text-sm font-medium leading-5 text-gray-700 mb-1;
}

.form-hint {
  @apply text-gray-600 text-xs italic;

  &.error {
    @apply text-red-500 text-sm italic leading-normal mt-1;
  }
}

.caret {
  @apply pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-800;
}

form.inline {
  .form-group {
    @apply flex justify-start items-center;
  }

  .form-group .input {
    @apply flex-1;
  }

  .form-group .label {
    @apply w-1/4;
  }
}

/* Fix flatpickr */
.flatpickr-current-month .flatpickr-monthDropdown-months {
  display: inline-block;
}

.form-checkbox {
  @apply rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

  &[disabled] {
    @apply bg-gray-200;
  }
}

.input-file {
  height: auto;
  z-index: 2;
  cursor: pointer;

  @apply opacity-0 inline-block pl-0 pr-0 py-3 px-3 overflow-hidden absolute border-none;

  + label {
    @apply inline-flex text-sm font-bold text-center px-4 py-0 rounded no-underline items-center justify-between cursor-pointer;

    height: 40px;
    line-height: 40px;
    transition: ease 0.3s background, ease 0.3s transform, ease 0.2s color;

    @apply btn;
    @apply btn-tertiary;

    &:focus {
      outline: 1px dotted #000;
      outline: -webkit-focus-ring-color auto 5px;
    }
  }
}

[type='checkbox'] {
  @apply form-checkbox;
  @apply rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50;

  &[disabled] {
    @apply bg-gray-200;
  }
}

[type='radio'] {
  @apply form-radio;
}
